<template>
    <v-container
        class="container"
        v-if="loading == false && error == false">
        <v-row>
            <v-col cols="12"
                ><h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                    {{ $t('reservedArea.investments.investments') }}
                </h1></v-col
            >
            <!-- Cards -->
            <v-col
                :cols="i >= 4 ? 12 : 12"
                :sm="i >= 5 ? 12 : 6"
                :md="i >= 5 ? 12 : 6"
                :xl="i >= 4 ? 6 : 4"
                :xxl="i >= 4 ? 6 : 4"
                class=""
                v-for="i in 5"
                :key="i">
                <v-card
                    class="tw-mx-auto"
                    variant="flat"
                    rounded="lg">
                    <template v-slot:title>
                        <p
                            v-if="i == 1"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.acquisitionValueInitial') }}
                        </p>
                        <p
                            v-if="i == 2"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.acquisitionValueCurrent') }}
                        </p>
                        <p
                            v-if="i == 3"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.currentValuation') }}
                        </p>
                        <p
                            v-if="i == 4"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.distributedDividends') }}
                        </p>
                        <p
                            v-if="i == 5"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.returnedCapital') }}
                        </p>
                    </template>
                    <v-card-actions>
                        <v-list-item class="w-100">
                            <!-- Acquisition Value (initial) -->
                            <v-list-item-title v-if="i == 1">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getAcquisitionValueInitial(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Acquisition Value (current) -->
                            <v-list-item-title v-if="i == 2">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getAcquisitionValueCurrent(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Current Valuation -->
                            <v-list-item-title v-if="i == 3">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getCurrentValuation(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Distributed Dividends -->
                            <v-list-item-title v-if="i == 4">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getDistributedDividends(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Returned Capital -->
                            <v-list-item-title v-if="i == 5">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getReturnedCapital(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <v-list-item-text>
                                <div class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-20 tw-bg-primary-light tw-rounded">
                                    <p class="tw-text-secondary tw-text-sm">{{ $t('reservedArea.investments.total') }}</p>
                                </div>
                            </v-list-item-text>
                            <template v-slot:append>
                                <div class="tw-flex tw-justify-center tw-items-center tw-h-12 tw-w-12 tw-bg-gray-default tw-rounded-full">
                                    <v-list-item-icon>
                                        <v-icon>
                                            <v-img
                                                v-if="i == 1"
                                                src="/images/ReservedArea/icons/wallet.svg"></v-img>
                                            <v-img
                                                v-if="i == 2"
                                                src="/images/ReservedArea/icons/wallet.svg"></v-img>
                                            <v-img
                                                v-if="i == 3"
                                                src="/images/ReservedArea/icons/graph.svg"></v-img>
                                            <v-img
                                                v-if="i == 4 || i == 5"
                                                src="/images/ReservedArea/icons/euro.svg"></v-img>
                                            <!-- <v-img
                                                v-if="i == 5"
                                                src="/images/ReservedArea/icons/calendar-silhouette.svg"></v-img> -->
                                        </v-icon>
                                    </v-list-item-icon>
                                </div>
                            </template>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- Table -->
            <v-col cols="12">
                <v-data-table-server
                    v-model:items-per-page="itemsPerPage"
                    :items-per-page-text="$t('reservedArea.investments.investmentsPerPage')"
                    :headers="headers"
                    :items-length="totalItems"
                    :items="serverItems"
                    :loading="loading"
                    class="!tw-rounded-lg"
                    item-value="name"
                    @update:options="loadItems">
                    <template #top>
                        <div class="!tw-text-center md:!tw-text-left !tw-text-secondary tw-text-xl !tw-font-bold !tw-border-none tw-p-4">
                            {{ $t('reservedArea.investments.investmentsHeader') }}
                        </div>
                    </template>
                    <!-- type -->
                    <template #[`item.type`]="{ item }">
                        <div
                            v-if="item.selectable.type == 'Gestão'"
                            class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-24 tw-bg-gray-light tw-rounded">
                            <p class="tw-text-secondary tw-text-center tw-text-xs">{{ $t('reservedArea.investments.management') }}</p>
                        </div>
                        <div
                            v-if="item.selectable.type == 'Subscrição'"
                            class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-24 tw-bg-primary-light tw-rounded">
                            <p class="tw-text-secondary tw-text-center tw-text-xs">{{ $t('reservedArea.investments.subscription') }}</p>
                        </div>
                    </template>
                    <!-- fund -->
                    <template #[`item.fund`]="{ item }">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">{{ item.selectable.name }}</p>
                            <p class="tw-text-secondary tw-text-left tw-text-sm tw-line-clamp-2">{{ item.selectable.excerpt }}</p>
                        </div>
                    </template>
                    <!-- acquisition value (initial) -->
                    <template #[`item.acquisitionValueInitial`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getAcquisitionValueInitial(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- acquisition value (current) -->
                    <template #[`item.acquisitionValueCurrent`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getAcquisitionValueCurrent(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- current valuation -->
                    <template #[`item.currentValue`]="{ item }">
                        <div>
                            <div v-if="item.selectable.investments">
                                <p class="tw-font-semibold tw-text-secondary tw-text-base">
                                    {{ getCurrentValuation(item.selectable.investments) }}
                                </p>
                                <div class="tw-flex tw-items-center tw-text-secondary tw-gap-x-1 tw-mt-1">
                                    <v-icon
                                        size="x-small"
                                        class="tw-p-2.5 tw-bg-secondary-light tw-rounded-md tw-bg-opacity-20"
                                        >mdi-calendar</v-icon
                                    >
                                    <p class="tw-text-sm">{{ formatDate(item.selectable.priceDate) }}</p>
                                </div>
                            </div>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- distributed dividends -->
                    <template #[`item.distributedValue`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getDistributedDividends(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- returned capital -->
                    <template #[`item.capitalDistribution`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getReturnedCapital(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn
                            variant="flat"
                            :to="{ path: `investment/${item.selectable.id}` }"
                            type="submit"
                            class="bg-primary text-black font-weight-bold !tw-text-base !tw-py-1"
                            max-height="none"
                            height="auto"
                            rounded="xs"
                            size="x-large">
                            {{ $t('reservedArea.investments.seeMore') }}
                        </v-btn>
                    </template>
                </v-data-table-server>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
    <!-- Error -->
    <div
        v-if="error"
        class="tw-h-full tw-items-center tw-justify-center tw-flex tw-gap-x-2 tw-p-4 md:tw-p-0 tw-text-center">
        <p class="tw-font-semibold">{{ $t('shared.error') }} ({{ errorMessage }} - {{ currentDate }})</p>
        <v-icon
            class="tw-text-primary"
            icon="mdi-alert-circle"></v-icon>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, watch, onMounted } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { getAllFunds, getCustomerFunds } from '@/api/dbn';
    import { me } from '@/api/user';
    import { useAuthStore } from '@/store/modules/auth';
    import { v4 as uuidv4 } from 'uuid';
    import moment from 'moment';
    import * as Sentry from '@sentry/vue';
    import { get } from 'lodash';
    const { t } = useI18n();
    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //data table
    const itemsPerPage = ref(15);
    interface Header {
        title: string;
        key: string;
        align?: string;
        sortable?: boolean;
        width?: string;
    }

    const headers = ref<Header[]>([]);

    const totalItems = ref(0);
    const loading = ref(true);
    const serverItems = ref([] as any[]);
    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    let user = authStore.getUser;
    //customer id and funds
    const allFunds = ref([]);
    const customerFunds = ref([]);
    const customerInvestments = ref([]);
    const error = ref(false);
    const errorMessage = ref('Error');
    //current datetime
    const currentDate = moment().format('DD/MM/YYYY HH:mm:ss');

    // watch for changes in the language, so we can update headers title
    watch(language, () => {
        setHeaders();
    });

    const setHeaders = () => {
        headers.value = [
            { title: t('reservedArea.investments.type'), align: 'start', sortable: false, key: 'type' },
            { title: t('reservedArea.investments.fund'), key: 'fund', width: '30%' },
            { title: t('reservedArea.investments.acquisitionValueInitial'), key: 'acquisitionValueInitial' },
            { title: t('reservedArea.investments.acquisitionValueCurrent'), key: 'acquisitionValueCurrent' },
            { title: t('reservedArea.investments.currentValuation'), key: 'currentValue' },
            { title: t('reservedArea.investments.distributedDividends'), key: 'distributedValue' },
            { title: t('reservedArea.investments.returnedCapital'), key: 'capitalDistribution' },
            { title: '', key: 'actions', align: 'end' },
        ];
    };

    //fetch customer funds, add type to each fund and format values
    const fetchCustomerFunds = async () => {
        await getCustomerFunds(language.value, user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                customerFunds.value = response.data;
                customerFunds.value.forEach((customerFund: any) => {
                    // handle duplicate customer funds
                    // duplicates exists cause the same fund can have different investment categories
                    let sameFunds = customerFunds.value.filter((fund: any) => fund.id === customerFund.id);
                    if (sameFunds.length > 1) {
                        customerFund.investments = [...new Map(sameFunds.flatMap((fund) => fund.investments).map((inv) => [inv.category, { ...inv, investmentId: uuidv4() }])).values()];
                    }
                    customerInvestments.value = [
                        ...new Map(
                            [
                                ...customerInvestments.value,
                                ...customerFund.investments.map((inv) => ({
                                    ...inv,
                                    fundId: customerFund.id,
                                    investmentId: inv.investmentId || uuidv4(),
                                })),
                            ].map((inv) => [inv.investmentId, inv]),
                        ).values(),
                    ];
                    // Definir o tipo do fundo
                    customerFund.type = 'Gestão';
                });
            })
            .finally(() => {
                fetchFunds();
            })
            .catch((err) => {
                console.log(err);
                if (err.toString().includes('TypeError')) errorMessage.value = '404';
                else errorMessage.value = err;
                error.value = true;
                loading.value = false;
            });
    };

    //fetch funds
    const fetchFunds = async () =>
        await getAllFunds(language.value, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                allFunds.value = response.data;
                allFunds.value = allFunds.value.filter((fund) => !customerFunds.value.some((customerFund) => customerFund.id === fund.id));
                allFunds.value.forEach((fund) => {
                    fund.type = 'Subscrição';
                });

                allFunds.value = [...allFunds.value, ...customerFunds.value];
            })
            .catch((err) => {
                console.log(err);
                if (err.toString().includes('TypeError')) errorMessage.value = '404';
                else errorMessage.value = err;
                error.value = true;
                loading.value = false;
            })
            .finally(() => {
                loadItems({ page: 1, itemsPerPage: 15, sortBy: [] });
            });

    //load table items
    const loadItems = async ({ page, itemsPerPage, sortBy }: any) => {
        //sort documents
        if (sortBy.length) {
            const sortKey = sortBy[0].key;
            const sortOrder = sortBy[0].order;
            allFunds.value = allFunds.value.sort((a, b) => {
                const sortA = a[sortKey];
                const sortB = b[sortKey];
                if (sortOrder === 'asc') {
                    return sortA > sortB ? 1 : -1;
                } else if (sortOrder === 'desc') {
                    return sortA < sortB ? 1 : -1;
                }
                return 0;
            });
        }
        //by default, the table is sorted by type (Gestão -> Subscrição)
        allFunds.value.sort((a: any, b: any) => {
            if (a.type == 'Gestão' && b.type == 'Subscrição') return -1;
            if (a.type == 'Subscrição' && b.type == 'Gestão') return 1;
            return 0;
        });
        serverItems.value = allFunds.value.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        totalItems.value = allFunds.value.length;
        loading.value = false;
    };
    const init = async () => {
        await me(
            {
                populate: ['role', 'dbns', 'children'],
            },
            {
                headers: {
                    Authorization: `Bearer ${authStore.getToken}`,
                },
            },
        ).then((response) => {
            user = response.data.user;
            authStore.setUser(response.data.user);
            fetchCustomerFunds();
        });
    };

    init();

    //methods to get values (acquisitionValueInitial, acquisitionValueCurrent, currentValuation, distributedDividends, returnedCapital)
    // Get initial acquisition value (investimento inicial)
    const getAcquisitionValueInitial = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.initialValue == null || investment.initialValue == 0) return sum;
            return sum + investment.initialValue;
        }, 0);
        return formatCurrency(sum);
    };

    // Get current acquisition value (investimento inicial - capital devolvido)
    const getAcquisitionValueCurrent = (investments) => {
        const sumInitial = investments.reduce((sum, investment) => {
            if (investment.initialValue == null || investment.initialValue == 0) return sum;
            return sum + investment.initialValue;
        }, 0);
        const sumReturnedCapital = investments.reduce((sum, investment) => {
            if (investment.nextDistributionValue == null || investment.nextDistributionValue == 0) return sum;
            return sum + investment.nextDistributionValue;
        }, 0);
        const sumCurrent = sumInitial - sumReturnedCapital;
        return formatCurrency(sumCurrent);
    };

    // Get current valuation (valor de mercado atual)
    const getCurrentValuation = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.value == null || investment.value == 0) return sum;
            return sum + investment.value;
        }, 0);
        return formatCurrency(sum);
    };

    // Get distributed dividends (dividendos distribuídos)
    const getDistributedDividends = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.distributedValue == null || investment.distributedValue == 0) return sum;
            return sum + investment.distributedValue;
        }, 0);
        return formatCurrency(sum);
    };

    // Get returned capital (capital devolvido)
    const getReturnedCapital = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.nextDistributionValue == null || investment.nextDistributionValue == 0) return sum;
            return sum + investment.nextDistributionValue;
        }, 0);
        return formatCurrency(sum);
    };
    //function to format currency
    const formatCurrency = (value) => {
        return value.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    //format date from YYYY-MM-DDTHH:mm:ss to DD/MM/YYYY
    const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    };

    onMounted(() => {
        setHeaders();
    });
</script>

<style scoped></style>
<style>
    .v-table--density-default > .v-table__wrapper > table > tbody > tr > td,
    .v-table--density-default > .v-table__wrapper > table > thead > tr > td,
    .v-table--density-default > .v-table__wrapper > table > tfoot > tr > td {
        height: calc(var(--v-table-row-height, 30px) + 50px);
    }
    /* .v-field__input {
        margin-top: 0.625rem;
    } */
</style>
