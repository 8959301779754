<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">{{ $t('auth.register.title1') }}</h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-16">{{ $t('auth.register.subtitle1') }}</h2>

            <v-form
                ref="form"
                @submit.prevent="submitRegister">
                <v-text-field
                    class="tw-mb-8"
                    name="username"
                    variant="outlined"
                    :placeholder="$t('auth.register.name')"
                    type="text"
                    :rules="[(v) => rules.required(v, $t('auth.register.requiredField'))]"
                    hide-details="auto"
                    v-model="username" />
                <v-text-field
                    class="tw-mb-8"
                    name="email"
                    variant="outlined"
                    :placeholder="$t('auth.register.email')"
                    type="email"
                    hide-details="auto"
                    :rules="[(v) => rules.required(v, $t('auth.register.requiredField')), (v) => rules.email(v, $t('auth.register.invalidEmail'))]"
                    v-model="email" />
                <v-text-field
                    class="tw-mb-8"
                    name="password"
                    variant="outlined"
                    :placeholder="$t('auth.register.password')"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                    hide-details="auto"
                    :rules="[(v) => rules.required(v, $t('auth.register.requiredField')), (v) => rules.min(v, 6, $t('auth.register.invalidPasswordLength'))]"
                    v-model="password" />
                <v-text-field
                    class="tw-mb-11"
                    name="confirm-password"
                    :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordConfirmationVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                    variant="outlined"
                    :placeholder="$t('auth.register.confirmPassword')"
                    hide-details="auto"
                    :rules="[
                        (v) => rules.required(v, $t('auth.register.requiredField')),
                        (v) => rules.min(v, 6, $t('auth.register.invalidPasswordLength')),
                        (v) => rules.comparePassword(v, password, $t('auth.register.passwordsDontMatch')),
                    ]"
                    v-model="passwordConfirmation" />
                <div
                    class="tw-flex tw-justify-end tw-z-10 tw-mt-[-12px] !tw-mb-4"
                    v-if="alertMessage">
                    <v-alert
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        :type="alertType"
                        >{{ alertMessage }}</v-alert
                    >
                </div>
                <div class="tw-flex tw-justify-center tw-mb-8">
                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        size="x-large"
                        rounded="xl"
                        color="primary"
                        @click.prevent="submitRegister()">
                        {{ $t('auth.register.register') }}
                    </v-btn>
                </div>
                <p class="tw-text-sm tw-font-semibold tw-text-center tw-text-secondary">
                    {{ $t('auth.register.alreadyHaveAnAccount')
                    }}<a
                        class="tw-text-sm tw-font-bold tw-text-center tw-text-secondary"
                        href="/auth/login"
                        >{{ $t('auth.register.login') }}</a
                    >
                </p>
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-mt-4 tw-gap-x-4">
                <span
                    class="fi fi-pt hover:tw-cursor-pointer"
                    @click="setLanguage('pt')"></span>
                <span
                    class="fi fi-gb hover:tw-cursor-pointer"
                    @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { register } from '@/api/auth';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRouter } from 'vue-router';
    import rules from '@/composables/rules';
    import { set } from 'lodash';
    import slugify from 'slugify';
    import _ from 'lodash';

    const i18n = useI18n();
    const store = useLanguageStore();

    const { t: $t } = useI18n();

    const form = ref(null) as any as VForm;
    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('success');

    const username = ref('');
    const email = ref('');
    const password = ref('');
    const passwordConfirmation = ref('');
    const isPasswordVisible = ref(false);
    const isPasswordConfirmationVisible = ref(false);

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const isLoading = ref(false);

    const submitRegister = async () => {
        if (!form.value) return;

        alertMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            await register({
                username: username.value,
                email: email.value,
                password: password.value,
                passwordConfirmation: passwordConfirmation.value,
            });

            alertType.value = 'success';
            alertMessage.value = $t('auth.registerSuccess');

            form.value.reset();
        } catch (error) {
            alertType.value = 'error';
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            alertMessage.value = $t(`auth.errors.${messageKey}`);
        } finally {
            isLoading.value = false;
            setTimeout(() => {
                alertMessage.value = '';
            }, 4000);
        }
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }
    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
