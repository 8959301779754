<template>
    <v-container
        class="container"
        v-if="loading == false && error == false">
        <v-row>
            <v-col cols="12">
                <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                    {{ $t('reservedArea.portfolio.portfolio') }}
                </h1>
            </v-col>
            <!-- Cards -->
            <v-col
                cols="12"
                sm="6"
                md="6"
                xl="6"
                xxl="3"
                class="tw-h-38"
                v-for="i in 4"
                :key="i">
                <v-card
                    class="tw-mx-auto tw-h-full"
                    variant="flat"
                    rounded="lg">
                    <template v-slot:title>
                        <p
                            v-if="i == 1"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-text-ellipsis tw-overflow-hidden">
                            {{ $t('reservedArea.portfolio.initialAcquisitionValue') }}
                        </p>
                        <p
                            v-if="i == 2"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.portfolio.currentValue') }}
                        </p>
                        <p
                            v-if="i == 3"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.portfolio.reinforcements') }}
                        </p>
                        <p
                            v-if="i == 4"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.portfolio.withdrawals') }}
                        </p>
                    </template>
                    <template v-slot:subtitle>
                        <p
                            v-if="i == 2"
                            class="tw-font-bold tw-text-secondary">
                            {{ currentValueDate }}
                        </p>
                    </template>
                    <v-card-actions :class="i == 2 ? '!tw-pt-0' : ''">
                        <v-list-item class="w-100">
                            <!-- Initial Acquisition Value -->
                            <v-list-item-title v-if="i == 1">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3 tw-mt-5">
                                    {{ formatValue(portfolio?.valorInicial, true) }}
                                </p>
                            </v-list-item-title>
                            <!-- Current Value -->
                            <v-list-item-title v-if="i == 2">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3 tw-mt-2">
                                    {{ formatValue(portfolio?.valorAtual, true) }}
                                </p>
                            </v-list-item-title>
                            <!-- Reinforcements -->
                            <v-list-item-title v-if="i == 3">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3 tw-mt-5">
                                    {{ formatValue(portfolio?.valorReforcos, true) }}
                                </p>
                            </v-list-item-title>
                            <!-- Withdrawals -->
                            <v-list-item-title v-if="i == 4">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3 tw-mt-5">
                                    {{ formatValue(portfolio?.valorLevantamentos, true) }}
                                </p>
                            </v-list-item-title>
                            <v-list-item-text>
                                <div class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-20 tw-bg-primary-light tw-rounded">
                                    <p class="tw-text-secondary tw-text-sm">{{ $t('reservedArea.investments.total') }}</p>
                                </div>
                            </v-list-item-text>
                            <template v-slot:append>
                                <div
                                    :class="i == 2 ? 'tw-mb-3' : ''"
                                    class="tw-flex tw-justify-center tw-items-center tw-h-12 tw-w-12 tw-bg-gray-default tw-rounded-full">
                                    <v-list-item-icon>
                                        <v-icon>
                                            <v-img
                                                v-if="i == 1"
                                                src="/images/ReservedArea/icons/wallet.svg"></v-img>
                                            <v-img
                                                v-if="i == 2"
                                                src="/images/ReservedArea/icons/graph.svg"></v-img>
                                            <v-icon
                                                v-if="i == 3"
                                                class="tw-text-secondary"
                                                size="small">
                                                mdi-plus
                                            </v-icon>
                                            <v-icon
                                                v-if="i == 4"
                                                class="tw-text-secondary"
                                                size="small">
                                                mdi-arrow-down
                                            </v-icon>
                                        </v-icon>
                                    </v-list-item-icon>
                                </div>
                            </template>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- Table -->
            <v-col cols="12">
                <v-data-table-server
                    v-model:items-per-page="itemsPerPage"
                    :items-per-page-text="$t('reservedArea.portfolio.investmentsPerPage')"
                    :headers="headers"
                    :items-length="totalItems"
                    :items="serverItems"
                    :loading="loading"
                    class="!tw-rounded-lg"
                    item-value="instrumento"
                    show-expand
                    v-model:expanded="expanded"
                    @update:options="loadItems">
                    <template #[`item.instrumento`]="{ item }">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">{{ item.selectable.instrumento }}</p>
                        </div>
                    </template>
                    <template #[`item.valorMoedaRefTotal`]="{ item }">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-mr-3 tw-text-end tw-text-sm">{{ formatValue(item.selectable.valorMoedaRefTotal, false) }}</p>
                        </div>
                    </template>
                    <template #[`item.weight`]="{ item }">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">{{ getWeight(item.selectable.valorMoedaRefTotal) }}</p>
                        </div>
                    </template>
                    <!-- expanded rows -->
                    <template v-slot:expanded-row="{ columns, item }">
                        <tr v-if="item.selectable?.items?.length > 0">
                            <td :colspan="columns.length">
                                <div class="tw-py-4 tw-min-w-max xl:tw-min-w-0">
                                    <!-- table header -->
                                    <v-row class="tw-font-semibold tw-text-secondary tw-text-sm">
                                        <v-col
                                            cols="2"
                                            class="tw-justify-center"
                                            >{{ $t('reservedArea.portfolio.asset') }}</v-col
                                        >
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.quantity') }}</v-col>
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.price') }}</v-col>
                                        <v-col
                                            cols="2"
                                            class="tw-justify-center"
                                            >{{ $t('reservedArea.portfolio.averagePurchasePrice') }}</v-col
                                        >
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.value') }}</v-col>
                                        <v-col
                                            cols="1"
                                            class="tw-justify-center"
                                            >{{ $t('reservedArea.portfolio.currency') }}</v-col
                                        >
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.valueEur') }}</v-col>
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.weight') }}</v-col>
                                        <v-col class="tw-justify-center">{{ $t('reservedArea.portfolio.isin') }}</v-col>
                                    </v-row>
                                    <!-- table body -->
                                    <v-row
                                        v-for="(subItem, index) in item.selectable?.items"
                                        :key="subItem.id"
                                        align-self="center">
                                        <v-col
                                            cols="2"
                                            class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ subItem.designacao }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ subItem.quantidade }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ formatValue(subItem.preco, false) }}</p>
                                        </v-col>
                                        <v-col
                                            cols="2"
                                            class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ formatValue(subItem.custoMedio, false) }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ formatValue(subItem.valor, false) }}</p>
                                        </v-col>
                                        <v-col
                                            cols="1"
                                            class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ subItem.moeda }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ formatValue(subItem.valorMoedaRef, false) }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ getWeight(subItem.valorMoedaRef) }}</p>
                                        </v-col>
                                        <v-col class="tw-justify-center">
                                            <p class="tw-text-secondary tw-text-sm">{{ subItem.isin }}</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <!-- footer -->
                    <template v-slot:footer.prepend>
                        <v-row>
                            <v-col
                                cols="12"
                                class="tw-flex tw-items-center tw-justify-start tw-my-3 tw-ml-1">
                                <div class="tw-flex tw-gap-x-2 tw-p-2 tw-font-semibold tw-text-secondary tw-bg-primary-light tw-rounded">
                                    <p>{{ $t('reservedArea.portfolio.totalValueEur') }}</p>
                                    <p class="">{{ totalValueEur }}</p>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table-server>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
    <!-- Error -->
    <div
        v-if="error"
        class="tw-h-full tw-items-center tw-justify-center tw-flex tw-gap-x-2 tw-p-4 md:tw-p-0 tw-text-center">
        <p class="tw-font-semibold">{{ $t('shared.error') }} ({{ errorMessage }} - {{ currentDate }})</p>
        <v-icon
            class="tw-text-primary"
            icon="mdi-alert-circle"></v-icon>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, watch, onMounted } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { getCustomerPortfolio } from '@/api/dbn';
    import { me } from '@/api/user';
    import { useAuthStore } from '@/store/modules/auth';
    import moment from 'moment';

    const { t } = useI18n();
    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //data table
    const itemsPerPage = ref(15);
    interface Header {
        title: string;
        key: string;
        align?: string;
    }
    const headers = ref([] as Header[]);
    const totalItems = ref(0);
    const loading = ref(true);
    const serverItems = ref([] as any[]);
    const expanded = ref([] as any[]);
    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    let user = authStore.getUser;
    //portfolio
    const portfolio = ref([]);
    const error = ref(false);
    const errorMessage = ref('Error');
    //current datetime
    const currentDate = moment().format('DD/MM/YYYY HH:mm:ss');

    // watch for changes in the language, so we can update headers title
    watch(language, () => {
        setHeaders();
    });

    const setHeaders = () => {
        headers.value = [
            { title: t('reservedArea.portfolio.asset'), key: 'instrumento' },
            { title: t('reservedArea.portfolio.valueEur'), key: 'valorMoedaRefTotal', align: 'end' },
            { title: t('reservedArea.portfolio.weight'), key: 'weight' },
        ];
    };

    //fetch customer portfolio
    const fetchCustomerPortfolio = async () =>
        //for now, im passing 0 as portfolioId
        await getCustomerPortfolio(user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, 0, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                portfolio.value = response.data;
                //group by instrumento
                //instrumentos are ETF, Fundos de Investimento Mobiliário, Depósitos à Ordem
                const items = portfolio.value.items;
                const instrumentos = items.map((item: any) => item.instrumento);
                const uniqueInstrumentos = [...new Set(instrumentos)];

                const groupedItems = uniqueInstrumentos.map((instrumento) => {
                    const filteredItems = items.filter((item: any) => item.instrumento === instrumento);
                    const valorMoedaRefTotal = filteredItems.reduce((sum, item) => sum + item.valorMoedaRef, 0);

                    return {
                        instrumento,
                        items: filteredItems,
                        valorMoedaRefTotal,
                    };
                });

                portfolio.value.items = groupedItems;
                expanded.value = portfolio.value.items.map((item: any) => item.instrumento);

                loading.value = false;
            })
            .catch((err) => {
                console.log(err);
                if (err.toString().includes('TypeError')) errorMessage.value = '404';
                else errorMessage.value = err;
                error.value = true;
                loading.value = false;
            });
    //load table items
    const loadItems = async ({ page, itemsPerPage, sortBy }: any) => {
        if (portfolio.value?.items?.length == 0) {
            loading.value = false;
            return;
        }
        //sort documents
        if (sortBy.length) {
            const sortKey = sortBy[0].key;
            const sortOrder = sortBy[0].order;
            portfolio.value.items = portfolio.value.items.sort((a, b) => {
                const sortA = a[sortKey];
                const sortB = b[sortKey];
                if (sortOrder === 'asc') {
                    return sortA > sortB ? 1 : -1;
                } else if (sortOrder === 'desc') {
                    return sortA < sortB ? 1 : -1;
                }
                return 0;
            });
        }
        //by default, the table is sorted by instrumento (ETF -> Fundos de Investimento Mobiliário -> Depósitos à ordem)
        //instrumento 'ETF' is always first
        //then 'Fundos de Investimento Mobiliário'
        //then 'Depósitos à Ordem'
        portfolio.value.items = portfolio.value.items.sort((a, b) => {
            if (a.instrumento == 'ETF' && b.instrumento != 'ETF') return -1;
            if (a.instrumento != 'ETF' && b.instrumento == 'ETF') return 1;
            if (a.instrumento == 'Fundos de Investimento Mobiliário' && b.instrumento != 'Fundos de Investimento Mobiliário') return -1;
            if (a.instrumento != 'Fundos de Investimento Mobiliário' && b.instrumento == 'Fundos de Investimento Mobiliário') return 1;
            if (a.instrumento == 'Depósitos à Ordem' && b.instrumento != 'Depósitos à Ordem') return -1;
            if (a.instrumento != 'Depósitos à Ordem' && b.instrumento == 'Depósitos à Ordem') return 1;
            return 0;
        });
        serverItems.value = portfolio.value?.items?.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        totalItems.value = portfolio.value?.items?.length;
        loading.value = false;
    };
    const init = async () => {
        await me(
            {
                populate: ['role', 'dbns', 'children'],
            },
            {
                headers: {
                    Authorization: `Bearer ${authStore.getToken}`,
                },
            },
        ).then((response) => {
            user = response.data.user;
            authStore.setUser(response.data.user);
            fetchCustomerPortfolio();
        });
    };
    //force init
    init();

    //get weight
    const getWeight = (value: number) => {
        if (value === undefined) return '-';

        const total = portfolio.value.items.reduce((sum, item) => sum + item.valorMoedaRefTotal, 0);
        const weight = (value / total) * 100;
        return weight.toFixed(2) + '%';
    };

    //current value date
    const currentValueDate = computed(() => {
        return moment(portfolio.value.valorizacao).format('DD/MM/YYYY');
    });

    //total value eur
    const totalValueEur = computed(() => {
        const total = portfolio.value.items.reduce((sum, item) => sum + item.valorMoedaRefTotal, 0);
        return total.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    });

    const formatValue = (value: number, showCurrency: boolean = true) => {
        if (value === undefined) return '-';

        // Converter para string para capturar a parte decimal real
        const valueStr = value.toString();
        let decimalPlaces = valueStr.includes('.') ? valueStr.split('.')[1].replace(/0+$/, '').length : 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        let formattedValue = value.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: showCurrency ? 'symbol' : 'code',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });

        if (!showCurrency) formattedValue = formattedValue.replace('EUR', '').trim();

        return formattedValue;
    };
    onMounted(() => {
        setHeaders();
    });
</script>

<style scoped></style>
<style>
    .v-table--density-default > .v-table__wrapper > table > tbody > tr > td,
    .v-table--density-default > .v-table__wrapper > table > thead > tr > td,
    .v-table--density-default > .v-table__wrapper > table > tfoot > tr > td {
        height: calc(var(--v-table-row-height, 30px) + 50px);
    }

    /* .v-field__input {
        margin-top: 0.625rem;
    } */
</style>
