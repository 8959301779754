// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/modules/auth';

import CommercialHome from '@/views/Commercial/Home.vue';
import ReservedAreaHome from '@/views/ReservedArea/Home.vue';
import AboutUs from '@/views/Commercial/AboutUs.vue';
// import Invest from '@/views/Commercial/Invest.vue';
// import Sell from '@/views/Commercial/Sell.vue';
// import Raise from '@/views/Commercial/Raise.vue';

// Auth
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
// import Dashboard from '@/views/ReservedArea/Dashboard.vue';

// Investment
import Investment from '@/views/ReservedArea/Investments/Investment.vue';
import InvestmentDetails from '@/views/ReservedArea/Investments/InvestmentDetails.vue';

// Portfolio
import Portfolio from '@/views/ReservedArea/Portfolio/Portfolio.vue';

// Novelty
import Novelty from '@/views/ReservedArea/Novelties/Novelty.vue';
import NoveltyDetails from '@/views/ReservedArea/Novelties/NoveltyDetails.vue';

// Helpful Document
import HelpfulDocument from '@/views/ReservedArea/HelpfulDocument.vue';

// Talk to us
import TalkToUs from '@/views/ReservedArea/TalkToUs.vue';

// Users
import Users from '@/views/ReservedArea/Users.vue';
import UsersAdmin from '@/views/ReservedArea/UsersAdmin.vue';

// Profile
import Profile from '@/views/ReservedArea/Profile/Profile.vue';

// Add User Request
import AddUserRequest from '@/views/ReservedArea/AddUserRequest.vue';

// Export Data
import ExportData from '@/views/ReservedArea/ExportData.vue';

// Administration
import Administration from '@/views/ReservedArea/Administration.vue';

// Website
import Website from '@/views/ReservedArea/Website.vue';

const routes = [
    {
        path: '/',
        component: CommercialHome,
        name: 'CommercialHome',
        children: [
            {
                path: '',
                component: AboutUs,
                name: 'AboutUs',
            },
            // {
            //     path: 'invest',
            //     component: Invest,
            //     name: 'Invest',
            // },
            // {
            //     path: 'sell',
            //     component: Sell,
            //     name: 'Sell',
            // },
            // {
            //     path: 'raise',
            //     component: Raise,
            //     name: 'Raise',
            // },
        ],
    },
    {
        path: '/reserved-area',
        component: ReservedAreaHome,
        name: 'ReservedAreaHome',
        children: [
            // {
            //     path: 'dashboard',
            //     component: Dashboard,
            //     name: 'Dashboard',
            // },
            {
                path: 'investment',
                component: Investment,
                name: 'Investment',
            },
            {
                path: 'investment/:id',
                component: InvestmentDetails,
                name: 'InvestmentDetails',
            },
            {
                path: 'portfolio',
                component: Portfolio,
                name: 'Portfolio',
            },
            {
                path: 'novelty',
                component: Novelty,
                name: 'Novelty',
            },
            {
                path: 'novelty/:id',
                component: NoveltyDetails,
                name: 'NoveltyDetails',
            },
            {
                path: 'helpful-document',
                component: HelpfulDocument,
                name: 'HelpfulDocument',
            },
            {
                path: 'talk-to-us',
                component: TalkToUs,
                name: 'TalkToUs',
            },
            {
                path: 'website',
                component: Website,
                name: 'Website',
            },
            {
                path: 'users',
                component: Users,
                name: 'Users',
            },
            {
                path: 'users-admin',
                component: UsersAdmin,
                name: 'UsersAdmin',
            },
            {
                path: 'users/add-user-request',
                component: AddUserRequest,
                name: 'AddUserRequest',
            },
            {
                path: 'export-data',
                component: ExportData,
                name: 'ExportData',
            },
            {
                path: 'administration',
                component: Administration,
                name: 'Administration',
            },
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
            }
        ],
    },
    {
        path: '/auth',
        component: Auth,
        name: 'Auth',
        children: [
            {
                path: 'login',
                component: Login,
                name: 'Auth-Login',
            },
            {
                path: 'register',
                component: Register,
                name: 'Auth-Register',
            },
            {
                path: 'forgot-password',
                component: ForgotPassword,
                name: 'Auth-ForgotPassword',
            },
            {
                path: 'reset-password',
                component: ResetPassword,
                name: 'Auth-ResetPassword',
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.getIsAuthenticated;
    const user = authStore.getUser;

    let hasGdWallets;
    //need hasGdWallets, take dbn code from user, and find it in user.dbns and check if hasGdWallets is true or false
    if (user?.dbns?.length) {
        const dbnCode = user.dbnCode || user.dbns[0]?.code;

        const dbn = user.dbns.find((dbn) => dbn.code === dbnCode);

        hasGdWallets = dbn?.hasGdWallets;
    }

    if (to.name === 'Investment' && hasGdWallets) {
        return next({ name: 'Portfolio' });
    }
    if (to.name === 'Portfolio' && !hasGdWallets) {
        return next({ name: 'Investment' });
    }

    if (to.path.includes('/reserved-area')) {
        if (isAuthenticated) {
            if (to.path.includes('/users-admin')) {
                if (user?.role?.name === 'Authenticated' || user?.role?.name === 'Manager') {
                    return next(hasGdWallets ? { name: 'Portfolio' } : { name: 'Investment' });
                }
            } else if (to.path.includes('/users')) {
                if (user?.role?.name === 'Manager') {
                    return next(hasGdWallets ? { name: 'Portfolio' } : { name: 'Investment' });
                }
                if (user?.role?.name === 'Admin') {
                    return next({ name: 'UsersAdmin' });
                }
            }
            return next();
        } else {
            return next({ name: 'Auth-Login' });
        }
    } else if (to.path.includes('/auth')) {
        if (isAuthenticated) {
            if (user?.role?.name === 'Admin') {
                return next({ name: 'UsersAdmin' });
            }
            return next(hasGdWallets ? { name: 'Portfolio' } : { name: 'Investment' });
        }
    }
    return next();
});

export default router;
