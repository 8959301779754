<template>
    <div>
        <div>
            <!-- Upload documents / Request data change (>768px) -->
            <div
                v-if="user?.role?.name !== 'Admin'"
                class="tw-hidden md:tw-block tw-text-center md:tw-text-end tw-mt-4">
                <v-btn
                    variant="flat"
                    type="submit"
                    class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    :loading="isSubmitBtnLoading"
                    @click="requestDataChange()">
                    {{ $t('reservedArea.profile.myPersonalData.requestDataChange') }}
                </v-btn>
            </div>
        </div>
        <!-- success alert >768px -->
        <div class="tw-hidden md:tw-block">
            <div
                class="tw-flex tw-justify-end tw-z-10 !tw-mt-4 !tw-mb-2"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.profile.myPersonalData.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
        <!-- person/company form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-8">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-pt-7">{{ $t('reservedArea.profile.myPersonalData.companyData') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled">
                <v-container class="">
                    <v-row>
                        <!-- name -->
                        <v-col
                            cols="12"
                            md="6">
                            <p
                                v-if="!myPersonalData.isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.profile.myPersonalData.personName') }}
                            </p>
                            <p
                                v-if="myPersonalData.isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.profile.myPersonalData.companyName') }}
                            </p>
                            <v-text-field
                                v-if="!myPersonalData.isCompany"
                                v-model="myPersonalData.name"
                                :label="$t('reservedArea.profile.myPersonalData.personName')"
                                variant="outlined"></v-text-field>
                            <v-text-field
                                v-if="myPersonalData.isCompany"
                                v-model="myPersonalData.name"
                                :label="$t('reservedArea.profile.myPersonalData.companyName')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- address -->
                        <v-col
                            cols="12"
                            md="6">
                            <div class="tw-flex tw-items-center tw-gap-x-2">
                                <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.address') }}</p>
                                <v-tooltip
                                    v-model="showAddressTooltip"
                                    location="top">
                                    <template v-slot:activator="{ props }">
                                        <v-icon
                                            size="small"
                                            v-bind="props"
                                            class="tw-text-primary tw-cursor-pointer tw-mb-3">
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ $t('reservedArea.profile.myPersonalData.addressTooltip') }}</span>
                                </v-tooltip>
                            </div>
                            <v-text-field
                                v-model="myPersonalData.address"
                                :label="$t('reservedArea.profile.myPersonalData.address')"
                                variant="outlined"
                                :error-messages="errorMessageAddress"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- nipc or nif -->
                        <v-col
                            cols="12"
                            xl="6">
                            <p
                                v-if="!myPersonalData.isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.profile.myPersonalData.nif') }}
                            </p>
                            <p
                                v-if="myPersonalData.isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.profile.myPersonalData.nipc') }}
                            </p>
                            <v-text-field
                                v-if="!myPersonalData.isCompany"
                                v-model="myPersonalData.nif"
                                :rules="[(v) => rules.nif(v, $t('reservedArea.profile.myPersonalData.invalidNif'))]"
                                :label="$t('reservedArea.profile.myPersonalData.nif')"
                                variant="outlined"></v-text-field>
                            <v-text-field
                                v-if="myPersonalData.isCompany"
                                v-model="myPersonalData.nif"
                                :rules="[(v) => rules.nipc(v, $t('reservedArea.profile.myPersonalData.invalidNipc'))]"
                                :label="$t('reservedArea.profile.myPersonalData.nipc')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- zip code -->
                        <v-col
                            cols="12"
                            xl="2">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">{{ $t('reservedArea.profile.myPersonalData.zipCode') }}</p>
                            <v-text-field
                                v-model="myPersonalData.postalCode"
                                :rules="[(v) => rules.zipCode(v, $t('reservedArea.profile.myPersonalData.invalidZipCode'))]"
                                :label="$t('reservedArea.profile.myPersonalData.zipCode')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- location -->
                        <v-col
                            cols="12"
                            xl="">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.location') }}</p>
                            <v-text-field
                                v-model="myPersonalData.city"
                                :label="$t('reservedArea.profile.myPersonalData.location')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- district -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.district') }}</p>
                            <v-text-field
                                v-model="myPersonalData.district"
                                :label="$t('reservedArea.profile.myPersonalData.district')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- bank -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.bank') }}</p>
                            <v-text-field
                                v-model="myPersonalData.bank"
                                :label="$t('reservedArea.profile.myPersonalData.bank')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- CAE -->
                        <v-col
                            v-if="myPersonalData.isCompany"
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">{{ $t('reservedArea.profile.myPersonalData.mainCae') }}</p>
                            <v-text-field
                                v-model="myPersonalData.cae"
                                :rules="[(v) => rules.cae(v, $t('reservedArea.profile.myPersonalData.invalidCae'))]"
                                :label="$t('reservedArea.profile.myPersonalData.mainCae')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- contact person form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-pt-7">{{ $t('reservedArea.profile.myPersonalData.contactPerson') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled"
                ref="contactPersonForm">
                <v-container class="">
                    <v-row>
                        <!-- contact person name -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.name') }}</p>
                            <v-text-field
                                v-model="myPersonalData.contactPerson"
                                :label="$t('reservedArea.profile.myPersonalData.name')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- email -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.email') }}</p>
                            <v-text-field
                                v-model="myPersonalData.email"
                                :rules="[(v) => rules.email(v, $t('reservedArea.profile.myPersonalData.invalidEmail'))]"
                                :label="$t('reservedArea.profile.myPersonalData.email')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- role -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.role') }}</p>
                            <v-text-field
                                v-model="myPersonalData.role"
                                :label="$t('reservedArea.profile.myPersonalData.role')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- phone -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.profile.myPersonalData.phone') }}</p>
                            <v-text-field
                                v-model="myPersonalData.phone"
                                :rules="[(v) => rules.phone(v, $t('reservedArea.profile.myPersonalData.invalidPhone'))]"
                                :label="$t('reservedArea.profile.myPersonalData.phone')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- upload documents form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-pt-7">{{ $t('reservedArea.profile.myPersonalData.uploadDocuments') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled"
                ref="documentsForm">
                <v-container class="">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12">
                            <v-file-input
                                v-model="myPersonalData.files"
                                :label="$t('reservedArea.profile.myPersonalData.uploadDocuments')"
                                outlined
                                multiple
                                @change="handleFilesUpload">
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- Upload documents / Request data change (<768px) -->
        <div
            v-if="user?.role?.name !== 'Admin'"
            class="tw-flex tw-flex-col tw-items-center md:tw-hidden tw-text-center tw-pb-8 tw-mt-6">
            <v-btn
                type="submit"
                class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 focus:!tw-bg-secondary focus:!tw-text-white"
                max-height="none"
                height="auto"
                rounded="xs"
                variant="flat"
                size="x-large"
                @click="requestDataChange()">
                {{ $t('reservedArea.profile.myPersonalData.requestDataChange') }}
            </v-btn>
        </div>
        <!-- success alert <768px -->
        <div class="tw-block md:tw-hidden">
            <div
                class="tw-flex tw-justify-end tw-z-10"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.profile.myPersonalData.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { createDataChangeRequest } from '@/api/dataChangeRequest';
    import { uploadFiles } from '@/api/upload';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import rules from '@/composables/rules';

    const { t: $t } = useI18n();

    const isDisabled = ref(false);
    const contactPersonForm = ref(null);

    const showAddressTooltip = ref(false);

    const props = defineProps({
        customerInfo: {
            type: Object,
            required: true,
        },
    });

    const myPersonalData = computed(() => props.customerInfo);

    const success = ref(false);

    const authStore = useAuthStore();
    const user = authStore.getUser;
    const token = authStore.getToken;

    const isSubmitBtnLoading = ref(false);

    const dbnCode = computed(() => user?.dbnCode || user?.dbns[0]?.code);
    const currentAddress = computed(() => user?.dbns.find((dbn) => dbn.code === dbnCode.value)?.address);

    const errorMessageAddress = computed(() => {
        return currentAddress.value !== myPersonalData.value.address && (!myPersonalData.value.files || myPersonalData.value.files.length === 0)
            ? $t('reservedArea.profile.myPersonalData.errorMessageAddress')
            : '';
    });

    const requestDataChange = () => {
        isSubmitBtnLoading.value = true;

        if (currentAddress.value !== myPersonalData.value.address && (!myPersonalData.value.files || myPersonalData.value.files.length === 0)) {
            isSubmitBtnLoading.value = false;
            return;
        }

        createDataChangeRequest(
            {
                data: {
                    user: {
                        id: authStore.getUser.id,
                    },
                    name: myPersonalData.value.name ? myPersonalData.value.name : null,
                    address: myPersonalData.value.address ? myPersonalData.value.address : null,
                    ...(myPersonalData.value.isCompany ? { nipc: myPersonalData.value.nif || null } : { nif: myPersonalData.value.nif || null }),
                    zipCode: myPersonalData.value.postalCode ? myPersonalData.value.postalCode : null,
                    location: myPersonalData.value.city ? myPersonalData.value.city : null,
                    cae: myPersonalData.value.cae ? myPersonalData.value.cae : null,
                    district: myPersonalData.value.district ? myPersonalData.value.district : null,
                    bank: myPersonalData.value.bank ? myPersonalData.value.bank : null,
                    contactPerson_name: myPersonalData.value.contactPerson ? myPersonalData.value.contactPerson : null,
                    contactPerson_email: myPersonalData.value.email ? myPersonalData.value.email : null,
                    contactPerson_role: myPersonalData.value.role ? myPersonalData.value.role : null,
                    contactPerson_phone: myPersonalData.value.phone ? myPersonalData.value.phone : null,
                    files: myPersonalData.value.files ? myPersonalData.value.files : null,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then(() => {
            success.value = true;
            isDisabled.value = true;
            isSubmitBtnLoading.value = false;
        });
    };

    const handleFilesUpload = () => {
        uploadFiles(myPersonalData.value.files, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            myPersonalData.value.files = response.data;
        });
    };

    const router = useRouter();

    const reloadPage = () => {
        router.go(0);
    };
</script>

<style scoped></style>
<style>
    .v-input--density-default {
        --v-input-padding-top: 0.2rem;
    }
</style>
