<template>
        <div class="tw-rounded-lg tw-mt-8">
            <v-switch
                v-model="documentNotifications"
                :label="$t('reservedArea.profile.notifications.receiveDocumentNotifications')"
                hide-details
                size="large"
                color="primary"
                flat
                class="custom-switch"
                inset></v-switch>
        </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { useAuthStore } from '@/store/modules/auth';
    import { updateDbn } from '@/api/dbn';

    const authStore = useAuthStore();
    const me = authStore.getUser;
    const token = authStore.getToken;

    const dbns = me?.dbns;
    const dbnCode = me?.dbnCode || dbns?.[0]?.code;

    // Computed com getter e setter para reatividade
    const documentNotifications = computed({
        get: () => {
            return dbns?.find((dbn) => dbn.code === dbnCode)?.documentNotifications ?? false;
        },
        set: async (value: boolean) => {
            const dbn = dbns?.find((dbn) => dbn.code === dbnCode);
            if (dbn) {
                dbn.documentNotifications = value;
                try {
                    await updateDbn(
                        dbn.id,
                        { data: { documentNotifications: value } },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                } catch (error) {
                    console.error('Error updating document notifications:', error);
                }
            }
        },
    });
</script>

<style scoped></style>
<style>
    .v-input--density-default {
        --v-input-padding-top: 0.2rem;
    }
</style>
