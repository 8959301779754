<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">{{ $t('auth.forgotPassword.title1') }}</h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-20">{{ $t('auth.forgotPassword.subtitle1') }}</h2>
            <v-form
                ref="form"
                @submit.prevent="submitForgotPassword">
                <v-text-field
                    class="tw-mb-8"
                    name="email"
                    variant="outlined"
                    :placeholder="$t('auth.forgotPassword.email')"
                    :rules="[(v) => rules.required(v, $t('auth.forgotPassword.requiredField')), (v) => rules.email(v, $t('auth.forgotPassword.invalidEmail'))]"
                    type="email"
                    hide-details="auto"
                    v-model="email" />
                <div
                    class="tw-flex tw-justify-end tw-z-10 tw-mt-[-12px] !tw-mb-4"
                    v-if="alertMessage">
                    <v-alert
                        :type="alertType"
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        >{{ alertMessage }}</v-alert
                    >
                </div>
                <div class="tw-flex tw-justify-center tw-mb-12">
                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        rounded="xl"
                        size="x-large"
                        color="primary"
                        @click.prevent="submitForgotPassword()">
                        {{ $t('auth.forgotPassword.submit') }}
                    </v-btn>
                </div>
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-gap-x-4">
                <span
                    class="fi fi-pt hover:tw-cursor-pointer"
                    @click="setLanguage('pt')"></span>
                <span
                    class="fi fi-gb hover:tw-cursor-pointer"
                    @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { forgotPassword } from '@/api/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import rules from '@/composables/rules';
    import slugify from 'slugify';
    import _ from 'lodash';

    const store = useLanguageStore();
    const i18n = useI18n();

    const { t: $t } = useI18n();

    const form = ref(null) as any as VForm;

    const email = ref('');
    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('success');

    const isLoading = ref(false);

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const submitForgotPassword = async () => {
        if (!form.value) return;

        alertMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;
        try {
            await forgotPassword({ email: email.value });
            alertMessage.value = $t('auth.forgotPassword.successText');
            alertType.value = 'success';
        } catch (error) {
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            alertMessage.value = $t(`auth.errors.${messageKey}`, '', {
                default: $t('auth.errors.unknown_error'),
            });
            alertType.value = 'error';
        }
        finally {
            isLoading.value = false;
        }
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }
    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
