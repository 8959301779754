<template>
    <v-container class="tw-flex tw-flex-col tw-gap-4 container">
        <div class="tw-flex tw-flex-col">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ $t('reservedArea.profile.profile') }}
            </h1>
            <v-tabs
                v-model="activeTab"
                :grow="$vuetify.display.smAndDown"
                class="md:tw-flex-1 tw-mt-2 custom-tabs">
                <v-tab value="myPersonalData">{{ $t('reservedArea.profile.myPersonalData.myPersonalData') }}</v-tab>
                <v-tab value="notifications">{{ $t('reservedArea.profile.notifications.notifications') }}</v-tab>
            </v-tabs>
            <MyPersonalData v-if="activeTab === 'myPersonalData' && !isLoading" :customer-info="customerInfo" />
            <Notifications v-else-if="activeTab === 'notifications'" />
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-h-full tw-max-h-[57%] tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useAuthStore } from '@/store/modules/auth';
    import { getCustomerInfo } from '@/api/dbn';
    import MyPersonalData from './MyPersonalData.vue';
    import Notifications from './Notifications.vue';

    const authStore = useAuthStore();
    const user = authStore.getUser;
    const token = authStore.getToken;

    const activeTab = ref<'myPersonalData' | 'notifications'>('myPersonalData');

    const customerInfo = ref({
        name: '',
        address: '',
        nipc: '',
        nif: '',
        zipCode: '',
        location: '',
        cae: '',
        district: '',
        bank: '',
        contactPersonName: '',
        email: '',
        role: '',
        phone: '',
        files: [],
    });

    const isLoading = ref(true);

    const fetchCustomerInfo = async () => {
        await getCustomerInfo(user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                customerInfo.value = response.data;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    fetchCustomerInfo();
</script>
