import axios from '@/plugins/axios';
import qs from 'qs';

export function login(data) {
    return axios.post('/auth/local', data);
}

export function register(data) {
    return axios.post('/auth/local/register', data);
}

export function forgotPassword(data) {
    return axios.post('/auth/forgot-password', data);
}

export function resetPassword(data) {
    return axios.post('/auth/reset-password', data);
}

export function resendEmail(data) {
    return axios.post('/auth/send-email-confirmation', data);
}

