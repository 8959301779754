<template>
    <v-container
        class="tw-flex tw-flex-col tw-gap-4 container"
        v-if="loading == false">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row tw-mb-4">
            <h1 class="tw-font-bold tw-text-secondary !tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4">
                {{ $t('reservedArea.users.users') }}
            </h1>
            <!-- Create (>768px) -->
            <div class="tw-hidden md:tw-block tw-text-center md:tw-text-left tw-mt-4 md:tw-mt-0">
                <v-btn
                    variant="flat"
                    type="submit"
                    :to="{ name: 'AddUserRequest' }"
                    class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    {{ t('reservedArea.users.create') }}
                </v-btn>
            </div>
        </div>
        <!-- Breadcrumbs -->
        <!-- <div class="tw-text-lg tw-text-secondary tw-text-left tw-hidden md:tw-block tw-mb-4">
            <v-breadcrumbs :items="breadCrumbItems" active-class="!tw-font-semibold" class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div> -->
        <!-- Table -->
        <v-data-table-server
            v-model:items-per-page="itemsPerPage"
            :items-per-page-text="$t('reservedArea.helpfulDocuments.filesPerPage')"
            :headers="headers"
            :items-length="totalItems"
            :items="serverItems"
            :loading="loading"
            :search="search"
            class="!tw-rounded-lg"
            item-value="name"
            @update:options="loadItems">
            <!-- filters -->
            <template #top>
                <v-row class="tw-px-4">
                    <v-col
                        cols="12"
                        class="tw-my-4">
                        <v-text-field
                            clearable
                            hide-details
                            :placeholder="$t('reservedArea.users.search')"
                            v-model="searchUser"
                            class="ma-2"
                            variant="outlined"
                            density="compact"></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <!-- contact person -->
            <template #[`item.name`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.username"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.username }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- nipc -->
            <template #[`item.nipc`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.NIF"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.NIF }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- jobTitle -->
            <template #[`item.jobTitle`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.jobTitle"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.jobTitle }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- email -->
            <template #[`item.email`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.email"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.email }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn
                    v-if="item.selectable.blocked == false"
                    type="submit"
                    @click="openDialog(true, item.selectable.id, item.selectable.username, item.selectable.blocked)"
                    class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1"
                    max-height="none"
                    variant="flat"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    {{ item.selectable.blocked == false ? $t('reservedArea.users.deactivate') : $t('reservedArea.users.activate') }}
                </v-btn>
                <v-btn
                    v-else
                    variant="flat"
                    type="submit"
                    @click="openDialog(true, item.selectable.id, item.selectable.username, item.selectable.blocked)"
                    class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    {{ $t('reservedArea.users.activate') }}
                </v-btn>
            </template>
        </v-data-table-server>
        <!-- Dialog so that the customer can confirm whether they really want to activate/deactivate the user -->
        <v-dialog
            v-model="dialog"
            persistent
            width="auto">
            <v-card
                variant="flat"
                rounded="lg">
                <v-card-text>
                    {{ blocked === false ? $t('reservedArea.users.sureDeactivate') : $t('reservedArea.users.sureActivate') }} <strong>{{ username }}</strong></v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        variant="flat"
                        class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1"
                        max-height="none"
                        height="auto"
                        rounded="xs"
                        size="x-large"
                        @click="dialog = false">
                        {{ $t('reservedArea.users.cancel') }}
                    </v-btn>
                    <v-btn
                        variant="flat"
                        class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1"
                        max-height="none"
                        height="auto"
                        rounded="xs"
                        size="x-large"
                        @click="submit(user, blocked)">
                        {{ $t('reservedArea.users.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Create (<768px) -->
        <div class="tw-flex tw-flex-col tw-items-center md:tw-hidden tw-text-center tw-pb-8 tw-mt-2">
            <v-btn
                variant="flat"
                type="submit"
                :to="{ name: 'AddUserRequest' }"
                class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                max-height="none"
                height="auto"
                rounded="xs"
                size="x-large">
                {{ t('reservedArea.users.create') }}
            </v-btn>
        </div>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { updateUser, me } from '@/api/user';
    import { useAuthStore } from '@/store/modules/auth';
    import { useLanguageStore } from '@/store/modules/language';

    const { t } = useI18n();

    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    const $router = useRouter();

    //data table
    const itemsPerPage = ref(15);

    interface Header {
        title: string;
        align: string;
        sortable: boolean;
        key: string;
    }
    const headers = ref([] as Header[]);
    const totalItems = ref(0);
    const loading = ref(true);
    const serverItems = ref([] as any[]);
    const search = ref('');
    const searchUser = ref('');

    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;

    //dialog
    const dialog = ref(false);
    const username = ref('');
    const blocked = ref(false);
    const user = ref(null);

    //watch search user
    watch(searchUser, () => {
        search.value = searchUser.value;
    });

    // watch for changes in the language, so we can update headers title
    watch(language, () => {
        setHeaders();
    });

    const setHeaders = () => {
        headers.value = [
            {
                title: t('reservedArea.users.contactPerson'),
                align: 'start',
                sortable: false,
                key: 'name',
            },
            { title: t('reservedArea.users.nipcOrTaxPayer'), key: 'nipc' },
            { title: t('reservedArea.users.jobTitle'), key: 'jobTitle' },
            { title: t('reservedArea.users.email'), key: 'email' },
            { title: '', key: 'actions' },
        ];
    };

    //load table items
    const loadItems = async ({ page, itemsPerPage, sortBy }: any) => {
        let filteredUsers = authStore.getUser?.children;

        //by search
        searchUser.value !== '' && searchUser.value !== null ? (filteredUsers = filteredUsers.filter((item) => item.username.toLowerCase().includes(searchUser.value.toLowerCase()))) : 0;

        //sort documents
        if (sortBy.length) {
            const sortKey = sortBy[0].key;
            const sortOrder = sortBy[0].order;
            filteredUsers = filteredUsers.sort((a, b) => {
                const sortA = a[sortKey];
                const sortB = b[sortKey];
                if (sortOrder === 'asc') {
                    return sortA > sortB ? 1 : -1;
                } else if (sortOrder === 'desc') {
                    return sortA < sortB ? 1 : -1;
                }
                return 0;
            });
        }
        serverItems.value = filteredUsers.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        totalItems.value = serverItems.value.length;
        loading.value = false;
    };

    loadItems({ page: 1, itemsPerPage: itemsPerPage.value, sortBy: [] });

    //handle dialog
    const openDialog = (value: boolean, userId: any, userName: any, isBlocked: boolean) => {
        dialog.value = value;
        username.value = userName;
        blocked.value = isBlocked;
        user.value = userId;
    };

    //update user based on the blocked value
    //if blocked is false, then the user will be blocked (deactivated)
    //if blocked is true, then the user will be unblocked (activated)
    const submit = (userId: any, blocked: boolean) => {
        updateUser(
            userId,
            {
                data: {
                    blocked: blocked ? false : true,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
            .then(async (response) => {
                const { data } = await me(
                    {
                        populate: ['role', 'dbns', 'children'],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authStore.getToken}`,
                        },
                    },
                );
                authStore.setUser(data.user);
            })
            .finally(() => {
                loadItems({ page: 1, itemsPerPage: itemsPerPage.value, sortBy: [] });
                $router.go(0);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    onMounted(() => {
        setHeaders();
    });
</script>

<style>
    .v-data-table-header__content {
        color: #b0b0b0;
        font-weight: 600 !important;
    }
    .v-data-table .v-table__wrapper > table > thead > tr > th,
    .v-data-table .v-table__wrapper > table tbody > tr > th {
        background-color: #f8f8f8;
    }
</style>
