<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">
                {{ $t('auth.login.title1') }}
            </h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-20">{{ $t('auth.login.subtitle1') }}</h2>
            <v-form
                ref="form"
                @submit.prevent="submitLogin">
                <v-text-field
                    class="tw-mb-8"
                    name="email"
                    variant="outlined"
                    :placeholder="$t('auth.login.emailOrNif')"
                    :rules="[(v) => rules.required(v, $t('auth.login.requiredField')), (v) => rules.emailOrNif(v, $t('auth.login.invalidEmailOrNif'))]"
                    type="email"
                    hide-details="auto"
                    v-model="emailOrNIF" />
                <v-text-field
                    class="tw-mb-6"
                    name="password"
                    variant="outlined"
                    :placeholder="$t('auth.login.password')"
                    :rules="[(v) => rules.required(v, $t('auth.login.requiredField')), (v) => rules.min(v, 6, $t('auth.login.invalidPasswordLength'))]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                    v-model="password"></v-text-field>
                <div
                    class="tw-flex tw-justify-end tw-z- tw-mb-2"
                    v-if="alertMessage">
                    <v-alert
                        class="!tw-justify-start"
                        dense
                        variant="tonal"
                        :type="alertType"
                        >{{ alertMessage }}
                        <a
                            v-if="alertSubType == 'your_account_email_is_not_confirmed'"
                            @click="_resendEmail"
                            class="tw-cursor-pointer tw-underline">
                            {{ $t('auth.errors.resend_email') }}
                        </a>
                    </v-alert>
                </div>
                <router-link
                    :to="{ name: 'Auth-ForgotPassword' }"
                    class="tw-font-semibold tw-text-secondary tw-text-sm tw-underline tw-flex tw-justify-end tw-mb-14"
                    >{{ $t('auth.login.forgotPassword') }}</router-link
                >
                <div class="tw-flex tw-justify-center tw-mb-20">
                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        rounded="xl"
                        size="x-large"
                        color="primary"
                        @click.prevent="submitLogin()">
                        {{ $t('auth.login.login') }}
                    </v-btn>
                </div>
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-mt-4 tw-gap-x-4">
                <span
                    class="fi fi-pt hover:tw-cursor-pointer"
                    @click="setLanguage('pt')"></span>
                <span
                    class="fi fi-gb hover:tw-cursor-pointer"
                    @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { login, resendEmail } from '@/api/auth';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRouter } from 'vue-router';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import rules from '@/composables/rules';
    import slugify from 'slugify';
    import _ from 'lodash';

    const i18n = useI18n();
    const store = useLanguageStore();

    const { t: $t } = useI18n();

    const form = ref(null) as any as VForm;

    const emailOrNIF = ref('');
    const password = ref('');
    const isPasswordVisible = ref(false);

    const isLoading = ref(false);
    const alertMessage = ref('');
    const alertSubType = ref('');
    const alertType = ref<'success' | 'error'>('success');

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const $router = useRouter();

    const submitLogin = async () => {
        if (!form.value) return;

        alertMessage.value = '';
        alertSubType.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await login({
                identifier: emailOrNIF.value,
                password: password.value,
            });

            //set dbn code to first dbn
            useAuthStore().setToken(data.jwt);
            useAuthStore().setUser(data.user);
            useAuthStore().setIsAuthenticated(true);
            isLoading.value = false;
            if (data.user.role.name === 'Admin') $router.push({ name: 'UsersAdmin' });
            else {
                const user = useAuthStore().user;

                //get dbnCode and find dbns with that code, so we can get hasGdWallets
                const dbns = user?.dbns;
                const dbnCode = user?.dbnCode || dbns?.[0]?.code;

                const hasGdWallets = dbns?.find((dbn) => dbn.code === dbnCode)?.hasGdWallets;

                if (hasGdWallets) $router.push({ name: 'Portfolio' });
                else $router.push({ name: 'Investment' });
            }
        } catch (error) {
            let messageKey = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
                replacement: '_',
                trim: true,
                lower: true,
            });
            alertType.value = 'error';
            alertSubType.value = messageKey;
            alertMessage.value = $t(`auth.errors.${messageKey}`);
        } finally {
            isLoading.value = false;
        }
    };

    const _resendEmail = async () => {
        isLoading.value = true;
        try {
            await resendEmail({
                email: emailOrNIF.value,
            });
            alertType.value = 'success';
            alertMessage.value = $t('auth.errors.email_confirmation_sent');
        } catch (error) {
            alertType.value = 'error';
            alertMessage.value = $t('auth.errors.email_confirmation_error');
        } finally {
            alertSubType.value = '';
            isLoading.value = false;
            setTimeout(() => {
                alertMessage.value = '';
            }, 4000);
        }
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }

    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
